<template>
    <div class="p-2" v-if="pet">

        <!-- USER HEADER INFO -->
        <div class="d-flex align-items-center" style="gap:10px 20px">
            <div>
                <img :src="pet.pet_picture ? pet.pet_picture : default_img" class="rounded-circle"
                    style="height:80px;width:80px" />
            </div>
            <div>
                <h3 class="text-white h4 mb-0">{{ pet.pet_name }}</h3>
                <h3 class="text-white h4 " style="margin: .25em 0 !important;">{{ pet.pet_breed }}</h3>
                <h3 class="text-white h4 mb-0">{{ pet.pet_age }} Months, {{ pet.pet_gender }}</h3>
            </div>
            <div class="ml-auto">
                <router-link :to="`/chats/${pet.user_id}`">
                    <i class="bx bxs-message-dots" style="font-size: 28px;cursor: pointer;color: #FFAD32;"></i>
                </router-link>
            </div>
        </div>
        <!-- END USER HEADER INFO -->

        <h5 class="text-white font-weight-bold h4 mt-2">Note for Walker</h5>
        <div class="mt-1" v-for="item in note">
            <div class="text-white" style="gap:10px">
                {{ moment(item.wn_date).format('dddd, DD MMMM YYYY') }}, {{ item.wn_time }}
            </div>
            <p class="text-white">
                {{ item.wn_text }}
            </p>
        </div>

        <div class="mt-1 d-flex" v-if="uid != pet.user_id">
            <router-link :to="`/chats/${pet.user_id}`" class="text-white btn ml-auto"
                style="padding:.5em 3em;gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;">
                <div class="h5 text-white mb-0">Chat With Owner</div>
            </router-link>
        </div>

        <h3 class="text-center text-white font-weight-bold h4 mt-3">Pet Walking Schedule</h3>
        <div class="mt-1" style="gap:10px" v-if="uid == pet.user_id">
            <div class="mt-1">

                <div class="d-flex align-items-center mb-1" style="gap:10px">
                    <input class="flex-fill form-control" type="date"
                        style="background: transparent;color: white;border:2px solid #E3A230;" v-model="date_input" />
                    <input class="flex-fill form-control" type="time"
                        style="background: transparent;color: white;border:2px solid #E3A230;" v-model="time_input" />
                </div>

                <div class="mt-1 d-flex">
                    <div class="text-white btn mx-auto"
                        style="padding:.5em 3em;gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;">
                        <div class="h4 text-white mb-0" @click="add_schedule()">Submit</div>
                    </div>
                </div>

            </div>
        </div>
        
        <div class="w-100 my-2" style="background-color: #E3A230;height: 2px;"></div>

        <div v-for="(item,key) in schedules">
            <h4 class="text-white mb-1 mt-2">Schedule {{ moment(key).format('dddd, DD MMMM YYYY') }}</h4> 
            <div v-for="time in item">
                <div class="text-white d-flex align-items-center p-1 mt-1" style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;">
                    <div class="h4 text-white mb-0">{{ time.ws_time }}</div>
                    <div class="h4 text-white mb-0 ml-auto" v-if="uid != pet.user_id" @click="bookNow(time.ws_id)">Book Now</div>
                    <div class="h4 text-white mb-0 ml-auto" v-else @click="del_schedule(time.ws_id)">Delete</div>
                    <i class="bx bx-chevron-right" style="font-size: 20px;"></i>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import store from '@/store'
import moment from 'moment'

import Swal from 'sweetalert2'

import Cookies from 'universal-cookie'
let cookies = new Cookies()

export default {
    computed: {
        pet() {
            return store.state.pet.PET_INFO
        },
        note() {
            return this.pet?.walker?.note
        },
        schedules() {
            return this.pet?.schedules_
        },
        uid() {
            return cookies.get('token')
        }
    },
    watch: {
        pet(val) {
            if (!val.walker) {
                let payload = new FormData()
                payload.append('inp[pet_id]', this.id)
                payload.append('inp[availability]', 'n')
                store.dispatch('walker/SetWalkerAvailability', payload).then(() => {
                    store.dispatch('pet/GetPet', this.id)
                })
            }
        },
    },
    methods: {
        bookNow(id){
            this.$router.push(`/walker/payment/${id}`)
        },
        add_schedule(){
            let payload = new FormData()
            payload.append('inp[ws_date]', this.date_input)
            payload.append('inp[ws_time]', this.time_input)
            payload.append('inp[wpa_id]', this.pet.walker.wpa_id)

            store.dispatch('walker/AddSchedule', payload).then(() => {
                store.dispatch('pet/GetPet', this.id)
                this.time_input = ''
                this.date_input = ''
            })
        },
        del_schedule(id) {
            Swal.fire({
                title: 'Do you want to delete this schedule?',
                showCancelButton: true,
                confirmButtonText: 'Save',
            }).then((result) => {
                if (result.isConfirmed) {
                    store.dispatch('walker/DeleteSchedule', id).then(() => {
                        store.dispatch('pet/GetPet', this.id)
                    })
                }
            })
        },
    },
    mounted() {
        store.dispatch('pet/GetPet', this.id)
    },
    data() {
        return {
            date_input: '',
            time_input: '',
            moment,
            id: this.$route.params.id,
            default_img: 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png'
        }
    }
}

</script>